<template>
  <div>
      <v-row>
        <v-col cols="6">
            <label>Display items on Dashboard Page</label><br>
            <div
            class="align-center"
              v-for="option in dashboardOptions"
              :key="option.value"
            >
              <v-checkbox
                v-model="selectedValues"
                :value="option.value"
                :label="option.title"
                hide-details
              />
            </div>
            <br>

            <v-btn
            color="info"
            style="text-transform: none;margin-top: 8px;"
            @click="optionClick()"
          >
            <div>Save</div>
          </v-btn>
        </v-col>
      </v-row>
    </div>
</template>

<script>
import { ref } from '@vue/composition-api'

export default {
  mounted() {
    this.$dateRangePickerDisplayFlag = false
    this.$datePickerDisplayFlag = false
    this.$selectScDisplayFlag = true
    this.$selectDomesticScDisplayFlag = false
    this.$domesticDatePickerDisplayFlag = false
  },
  methods: {
    optionClick() {
      console.log(this.selectedValues)
      localStorage.setItem('dashboardDisplayItems', JSON.stringify(this.selectedValues))
      this.$toasted.success('Your data has been successfully saved')
    },
  },
  setup() {
    const selectedValues = ref([])
    const dashboardDisplayItems = localStorage.getItem('dashboardDisplayItems')
    console.log(dashboardDisplayItems)
    if (dashboardDisplayItems === null) {
      selectedValues.value = [1, 2, 3, 4, 5]
    } else {
      selectedValues.value = JSON.parse(dashboardDisplayItems)
    }

    const dashboardOptions = [
      { title: 'People & Last Year', value: 1 },
      { title: 'People, Car, Bike', value: 2 },
      { title: 'Staying Visitor', value: 3 },
      { title: 'SC Comparison', value: 4 },
      { title: 'Weather Information', value: 5 },
    ]

    return {
      selectedValues,
      dashboardOptions,
    }
  },
}
</script>
